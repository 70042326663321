import React from 'react';
import { Box, Typography } from '@mui/material';
import ServiceCheckbox from './ServiceCheckbox';

const ExtraServicesSection = ({ formValues, errors }) => {
  const extraServices = [
    { key: 'hotel', label: 'Hotel Provided' },
    { key: 'rentalCar', label: 'Rental Car Provided' },
    { key: 'transportation', label: 'Transportation Provided' },
  ];

  return (
    <>
      <Typography variant="h5">Exstra services</Typography>
      <Box display="flex" flexDirection="row" gap={2} marginBottom={2}>
        {extraServices.map(({ key, label }) => (
          <ServiceCheckbox
            key={key}
            label={label}
            checked={Boolean(formValues.extraService?.[key])}
          />
        ))}
      </Box>
    </>
  );
};

export default ExtraServicesSection;
