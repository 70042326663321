import { create } from 'zustand';

const initialSearchResult = {
  key: null,
  type: null,
  name: null,
  address: null,
  lat: null,
  lon: null,
  businessName: null,
  streetNumber: null,
  street: null,
  countryCode: null,
  contryCodeISO: null,
  countrySubdivision: null,
  postalCode: null,
  country: null,
  freeformAddress: null,
  positionString: null,
};

const useSearchStore = create((set) => ({
  searchResult: { ...initialSearchResult },

  setSearchResult: (result) => {
    // console.log('Setting search result:', result);
    set({ searchResult: result });
    // console.log('Updated search result in store:', result);
  },

  clearSearchResult: () => {
    set({ searchResult: { ...initialSearchResult } });
  },
}));

export default useSearchStore;
