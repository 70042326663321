import React from 'react';
import CustomErrorContainer from './CustomErrorContainer';
import { Box, Typography } from '@mui/material';
import UrgentCase from './UrgentCase';

const SuccessContent = ({ id, urgency, station }) => {
  const handleViewCase = () => {
    const url = process.env.REACT_APP_MISSING_DATA + `${id}/view`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  console.log(urgency);

  const isUrgent = urgency === '1. IMMEDIATE PRIORITY' || urgency === '2. HIGH PRIORITY';

  return (
    <CustomErrorContainer
      severity="success"
      title="Operation Successful"
      message="The operation was completed successfully."
      actions={[{ label: 'View Case in SalesForce', onClick: handleViewCase }]}
    >
      <Typography>
        {isUrgent ? (
          <Box
            sx={{
              boxShadow: 3,
            }}
          >
            <UrgentCase stationDetails={station} />
          </Box>
        ) : (
          <></>
        )}
      </Typography>
    </CustomErrorContainer>
  );
};

export default SuccessContent;
