import React, { useState, useEffect, useRef } from 'react';
import {
  IconButton,
  InputAdornment,
  TextField,
  MenuItem,
  FormControl,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import { countryCodes } from './countryCodes';
import { registrationFormat } from './registrationFormat';
import useRegSearchStore from '../../../hooks/store/useRegSearchStore';
import useRegSearch from '../../../hooks/Search/useRegSearch';

const RegistrationSearch = ({ registrationNumber, onChange, loading, error, setFormik }) => {
  const [localRegistrationNumber, setLocalRegistrationNumber] = useState(registrationNumber || '');
  const [selectedCountry, setSelectedCountry] = useState(() => {
    const storedCountry = localStorage.getItem('regNumCountry');
    return storedCountry ? storedCountry : 'NO';
  });

  const [searchStatus, setSearchStatus] = useState('idle');
  const [inputError, setInputError] = useState('');

  const timeoutRef = useRef(null);
  const { fetchRegData } = useRegSearch();

  // Accessing Zustand store
  const setRsaData = useRegSearchStore((state) => state.setRsaData);
  const setLoading = useRegSearchStore((state) => state.setLoading);
  const setError = useRegSearchStore((state) => state.setError);
  const resetStore = useRegSearchStore((state) => state.reset);

  const {
    pcdData,
    caseData,
    rsaData,
    loading: storeLoading,
    error: storeError,
  } = useRegSearchStore();

  useEffect(() => {
    console.log('Store data:', {
      pcdData,
      caseData,
      rsaData,
      loading: storeLoading,
      error: storeError,
    });
  }, [pcdData, caseData, rsaData, storeLoading, storeError]);

  useEffect(() => {
    localStorage.setItem('regNumCountry', selectedCountry);
  }, [selectedCountry]);

  const validateRegistrationNumber = (number, country) => {
    const pattern = registrationFormat[country];
    if (pattern && !pattern.test(number)) {
      return `Invalid format for ${country}`;
    }
    return '';
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setLocalRegistrationNumber(value);
    onChange(e);

    const validationError = validateRegistrationNumber(value, selectedCountry);
    setInputError(validationError);

    if (!e.target.vaslue) resetStore();
  };

  useEffect(() => {
    if (inputError || !localRegistrationNumber) return;

    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      handleSearchClick(localRegistrationNumber);
    }, 1000);

    return () => clearTimeout(timeoutRef.current);
  }, [localRegistrationNumber, selectedCountry]); //eslint-disable-line

  const handleCountryChange = (e) => {
    const newCountry = e.target.value;
    setSelectedCountry(newCountry);
    const validationError = validateRegistrationNumber(localRegistrationNumber, newCountry);
    setInputError(validationError);
  };

  const handleSearchClick = async (searchValue) => {
    if (!searchValue) resetStore();

    const validationError = validateRegistrationNumber(searchValue, selectedCountry);
    if (validationError) {
      setInputError(validationError);
      resetStore();
      return;
    }

    try {
      setSearchStatus('loading');
      setLoading(true);
      const rsaData = await fetchRegData(searchValue, setFormik);
      setRsaData(rsaData);
      setSearchStatus('idle');
    } catch (error) {
      setSearchStatus('error');
      setInputError('Registration not found (404)');
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tooltip title="Search on registration number" placement="top">
      <FormControl
        variant="outlined"
        fullWidth
        error={!!inputError || error}
        style={{ marginTop: 16 }}
      >
        <TextField
          variant="outlined"
          name="registrationNumber"
          placeholder="Enter registration number"
          value={localRegistrationNumber.toUpperCase()}
          onChange={handleInputChange}
          error={!!inputError || !!error}
          helperText={inputError || error}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TextField
                  select
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  variant="standard"
                >
                  {countryCodes.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      ({option.name}) {option.code}
                    </MenuItem>
                  ))}
                </TextField>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleSearchClick(localRegistrationNumber)}
                  disabled={loading}
                >
                  {searchStatus === 'loading' ? (
                    <CircularProgress size={24} />
                  ) : searchStatus === 'error' ? (
                    <ErrorIcon color="error" />
                  ) : (
                    <SearchIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </Tooltip>
  );
};

export default RegistrationSearch;
