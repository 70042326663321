import * as Yup from 'yup';

const reviewPageValidationSchema = Yup.object({
  registrationNumber: Yup.string()
    .required('Registration Number is required')
    .matches(/^[\w-]+$/, 'Invalid characters in Registration Number'),
  carModel: Yup.string().required('Car Model is required'),
  insuranceCompany: Yup.string()
    .required('Insurance Company is required')
    .min(2, 'Insurance Company name must be at least 2 characters long'),
  coverCause: Yup.string().required('Cover Cause is required'),
  coverage: Yup.string()
    .required('Coverage is required')
    .oneOf(['Full', 'Partial', 'Basic'], 'Invalid Coverage option'),
  ownerName: Yup.string().required('Owner Name is required'),
  ownerBillingAddress: Yup.string().required('Billing Address is required'),
  ownerBillingCity: Yup.string().required('Billing City is required'),
  ownerPostalCode: Yup.string().required('Billing Postalcode is required'),
  customerName: Yup.string().required('Customer Name is required'),
  customerNumber: Yup.string().required('Customer Number is required'),
  location: Yup.string().required('Location is required'),
  station: Yup.string().required('Station is required'),
  workShopName: Yup.string().required('Work Shop Name is required'),
  workShopAddress: Yup.string().required('Work Shop Address is required'),
  caseComment: Yup.string().required('Case Comment is required'),
  priority: Yup.string().required('Priority is required'),
});

const otherFormValidationSchema = Yup.object({
  registrationNumber: Yup.string().required('Registration number is required'),
  channel: Yup.string().required('Channel is required'),
  location: Yup.object({
    address: Yup.string().required('Address is required'),
    postalCode: Yup.string().required('Postal code is required'),
    city: Yup.string().required('City is required'),
  }),
  onSiteContact: Yup.object({
    contactName: Yup.string().required('Contact name is required'),
    countryCode: Yup.string().required('Country code is required'),
    phone: Yup.string().required('Phone number is required'),
  }),
  coverCause: Yup.string().required('Cover cause is required'),
  priority: Yup.string().required('Priority is required'),
  pcdData: Yup.object({
    insuranceCompany: Yup.string(),
    lastName: Yup.string(),
    make: Yup.string(),
    model: Yup.string(),
    registrationNumber: Yup.string(),
    // address: Yup.string(),
    // bodyType: Yup.string(),
    // city: Yup.string(),
    // colour: Yup.string(),
    // dateOfFirstRegistration: Yup.string(),
    // fuelType: Yup.string(),
    // insuranceCompany: Yup.string(),
    // lastName: Yup.string(),
    // length: Yup.string(),
    // make: Yup.string(),
    // model: Yup.string(),
    // numberOfAxlesInOperation: Yup.string(),
    // organizationNumber: Yup.string(),
    // registrationNumber: Yup.string(),
    // totalNumberOfAxles: Yup.string(),
    // vin: Yup.string(),
    // weight: Yup.string(),
    // width: Yup.string(),
    // year: Yup.string(),
    // zip: Yup.string(),
  }),
});

export const useValidationSchema = (formType) => {
  const getValidationSchema = () => {
    switch (formType) {
      case 'reviewPage':
        return reviewPageValidationSchema;
      case 'otherForm':
        return otherFormValidationSchema;
      default:
        return null;
    }
  };

  return { validationSchema: getValidationSchema() };
};
