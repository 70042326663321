import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material';
import React from 'react';
import './alertStyle.css';

const UrgentCase = ({ stationDetails }) => {
  const stationNumber = stationDetails?.phoneNumber || null;
  const stationName = stationDetails?.StationName || null;
  const priorityHigh = stationDetails?.priority || null;

  return (
    <Alert severity="error" variant="filled" className="blink__box">
      <AlertTitle>You need to contact the station due to {priorityHigh}</AlertTitle>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Box>
          <Typography>Station added on the case is: {stationName}</Typography>
          <Typography>Contact the station on tlf: {stationNumber}</Typography>
        </Box>
        <Button variant="text" color="info">
          Open in puzzle
        </Button>
      </Box>
    </Alert>
  );
};

export default UrgentCase;
