import { useMutation } from "@tanstack/react-query";
import { useAxiosInstance } from "../../api/axiosInstanceTwo"; 

export const usePostRsaCase = () => {
  const axiosInstance = useAxiosInstance(); 

  return useMutation({
    mutationFn: async (body) => {
      const urlRsa = process.env.REACT_APP_RSA_API_BASE_URL
      console.log('Request body:', body);
      try {
        const response = await axiosInstance.post(urlRsa, body);
        console.log('Request:', response); 
        localStorage.setItem('response', JSON.stringify(response.data));
        return response.data; 
      } catch (error) {
        console.error('API call failed:', error.message);
        throw error; 
      }
    },
  });
};