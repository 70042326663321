import React, { useState, useEffect, useCallback } from 'react';
import Section from '../../components/reviewPage/Section/Section';
import FormLayout from '../../components/reviewPage/FormLayout';
import ReviewFormButton from '../../components/Button/ReviewFormButton';
import ValidatedTextField from '../../components/reviewPage/textField/ValidatedTextField';
import categorizeData from '../../components/reviewPage/utils/categorizeData';
import InfoSection from '../../components/reviewPage/Section/InfoSection';

const PageLayout = ({ caseData }) => {
  console.log('Review page caseData:', caseData);

  const [formValues] = useState(categorizeData(caseData));
  const [errors, setErrors] = useState({});

  const validateFields = useCallback(() => {
    const newErrors = {};

    Object.keys(formValues).forEach((key) => {
      if (key === 'owner') {
        const owner = formValues.owner;
        const isCompany = !!owner?.name;

        if (isCompany) {
          if (!owner?.name) {
            newErrors['owner.name'] = 'Required';
          }
        } else {
          if (!owner?.firstName) {
            newErrors['owner.firstName'] = 'Required';
          }
          if (!owner?.lastName) {
            newErrors['owner.lastName'] = 'Required';
          }
        }
      } else if (typeof formValues[key] === 'object') {
        Object.keys(formValues[key]).forEach((subKey) => {
          if (key === 'extraService') {
            return;
          }

          if (!formValues[key][subKey]) {
            newErrors[`${key}.${subKey}`] = 'Required';
          }
        });
      } else if (!formValues[key]) {
        newErrors[key] = 'Required';
      }
    });

    setErrors(newErrors);
  }, [formValues]);

  useEffect(() => {
    validateFields();
  }, [formValues, validateFields]);

  const isComplete = (sectionKey) => {
    return !Object.keys(errors).some((errorKey) => errorKey.startsWith(sectionKey));
  };

  const isCompany = !!formValues.owner.name;

  let hasOwnerError;

  console.log(errors);

  return (
    <div>
      <InfoSection formValues={formValues} error={errors} />

      <FormLayout>
        {/* Case Details Section */}
        <Section
          heading="Case Details"
          hasError={Boolean(errors['caseDetails'])}
          isComplete={isComplete('caseDetails')}
        >
          <ValidatedTextField
            name="caseDetails.caseId"
            label="Case ID"
            value={formValues.caseDetails?.caseId || ''}
            error={Boolean(errors['caseDetails.caseId'])}
            helperText={errors['caseDetails.caseId']}
          />
          <ValidatedTextField
            name="caseDetails.caseNumber"
            label="Case Number"
            value={formValues.caseDetails?.caseNumber || ''}
            error={Boolean(errors['caseDetails.caseNumber'])}
            helperText={errors['caseDetails.caseNumber']}
          />
          <ValidatedTextField
            name="caseDetails.contactChannel"
            label="Contact Channel"
            value={formValues.caseDetails?.contactChannel || ''}
            error={Boolean(errors['caseDetails.contactChannel'])}
            helperText={errors['caseDetails.contactChannel']}
          />
        </Section>

        {/* Customer Section */}
        <Section
          heading="Customer"
          hasError={Boolean(errors['customer'])}
          isComplete={isComplete('customer')}
        >
          <ValidatedTextField
            name="customer.contactName"
            label="Contact Name"
            value={formValues.customer?.contactName || ''}
            error={Boolean(errors['customer.contactName'])}
            helperText={errors['customer.contactName']}
          />
          <ValidatedTextField
            name="customer.contactNumber"
            label="Contact Number"
            value={formValues.customer?.contactNumber || ''}
            error={Boolean(errors['customer.contactNumber'])}
            helperText={errors['customer.contactNumber']}
          />
        </Section>

        {/* Vehicle Section */}
        <Section
          heading="Vehicle Details"
          hasError={Boolean(errors['car'])}
          isComplete={isComplete('car')}
        >
          <ValidatedTextField
            name="car.model"
            label="Car Model"
            value={formValues.car?.model || ''}
            error={Boolean(errors['car.model'])}
            helperText={errors['car.model']}
          />
          <ValidatedTextField
            name="car.make"
            label="Car Make"
            value={formValues.car?.make || ''}
            error={Boolean(errors['car.make'])}
            helperText={errors['car.make']}
          />
          <ValidatedTextField
            name="car.type"
            label="Car Type"
            value={formValues.car?.type || ''}
            error={Boolean(errors['car.type'])}
            helperText={errors['car.type']}
          />
          <ValidatedTextField
            name="car.insurance"
            label="Insurance"
            value={formValues.car?.insurance || ''}
            error={Boolean(errors['car.insurance'])}
            helperText={errors['car.insurance']}
          />
          <ValidatedTextField
            name="car.registrationNumber"
            label="Registration Number"
            value={formValues.car?.registrationNumber || ''}
            error={Boolean(errors['car.registrationNumber'])}
            helperText={errors['car.registrationNumber']}
          />
        </Section>

        {/* Owner Section */}
        <Section
          heading={isCompany ? 'Company Information' : 'Owner Information'}
          hasError={hasOwnerError}
          isComplete={isComplete('owner')}
        >
          {formValues.owner?.name ? (
            <ValidatedTextField
              name="owner.name"
              label="Company name"
              value={formValues.owner?.name || ''}
              error={Boolean(errors['owner.name'])}
              helperText={errors['owner.name']}
            />
          ) : (
            <>
              <ValidatedTextField
                name="owner.firstName"
                label="First Name"
                value={formValues.owner?.firstName || ''}
                error={Boolean(errors['owner.firstName'])}
                helperText={errors['owner.firstName']}
              />
              <ValidatedTextField
                name="owner.lastName"
                label="Last Name"
                value={formValues.owner?.lastName || ''}
                error={Boolean(errors['owner.lastName'])}
                helperText={errors['owner.lastName']}
              />
            </>
          )}
        </Section>

        {/* Agreement Section */}
        <Section
          heading="Agreement | Coverage"
          hasError={Boolean(errors['agreement'])}
          isComplete={isComplete('agreement')}
        >
          <ValidatedTextField
            name="agreement.coverCause"
            label="Cover Cause"
            value={formValues.agreement?.coverCause || ''}
            error={Boolean(errors['agreement.coverCause'])}
            helperText={errors['agreement.coverCause']}
          />
          <ValidatedTextField
            name="agreement.coverageId"
            label="Coverage ID"
            value={formValues.agreement?.coverageId || ''}
            error={Boolean(errors['agreement.coverageId'])}
            helperText={errors['agreement.coverageId']}
          />
          <ValidatedTextField
            name="agreement.coverageInfo"
            label="Coverage Info"
            value={formValues.agreement?.coverageInfo || ''}
            error={Boolean(errors['agreement.coverageInfo'])}
            helperText={errors['agreement.coverageInfo']}
          />
          <ValidatedTextField
            name="agreement.isProblemCovered"
            label="Is Problem Covered?"
            value={formValues.agreement?.isProblemCovered ? 'Yes' : 'No'}
            error={Boolean(errors['agreement.isProblemCovered'])}
            helperText={errors['agreement.isProblemCovered']}
          />
        </Section>

        {/* Location Section */}
        <Section
          heading="Location"
          hasError={Boolean(errors['location'])}
          isComplete={isComplete('location')}
        >
          <ValidatedTextField
            name="location.address"
            label="Location Address"
            value={formValues.location?.address || ''}
            error={Boolean(errors['location.address'])}
            helperText={errors['location.address']}
          />
          <ValidatedTextField
            name="location.postalCode"
            label="Postal Code"
            value={formValues.location?.postalCode || ''}
            error={Boolean(errors['location.postalCode'])}
            helperText={errors['location.postalCode']}
          />
          <ValidatedTextField
            name="location.city"
            label="City"
            value={formValues.location?.city || ''}
            error={Boolean(errors['location.city'])}
            helperText={errors['location.city']}
          />
        </Section>

        {/* Station Section */}
        <Section
          heading="Station"
          hasError={Boolean(errors['station'])}
          isComplete={isComplete('station')}
        >
          <ValidatedTextField
            name="station.stationId"
            label="Station ID"
            value={formValues.station?.stationId || ''}
            error={Boolean(errors['station.stationId'])}
            helperText={errors['station.stationId']}
          />
          <ValidatedTextField
            name="station.stationName"
            label="Station Name"
            value={formValues.station?.stationName || ''}
            error={Boolean(errors['station.stationName'])}
            helperText={errors['station.stationName']}
          />
          <ValidatedTextField
            name="station.priority"
            label="Priority"
            value={formValues.station?.priority || ''}
            error={Boolean(errors['station.priority'])}
            helperText={errors['station.priority']}
          />
        </Section>

        {/* Destination Section */}
        <Section
          heading="Workshop"
          hasError={Boolean(errors['destination'])}
          isComplete={isComplete('destination')}
        >
          <ValidatedTextField
            name="destination.destinationAddress"
            label="Destination Address"
            value={formValues.destination?.destinationAddress || ''}
            error={Boolean(errors['destination.destinationAddress'])}
            helperText={errors['destination.destinationAddress']}
          />
          <ValidatedTextField
            name="destination.destinationCity"
            label="Destination City"
            value={formValues.destination?.destinationCity || ''}
            error={Boolean(errors['destination.destinationCity'])}
            helperText={errors['destination.destinationCity']}
          />
        </Section>
      </FormLayout>

      <ReviewFormButton />
    </div>
  );
};

export default PageLayout;
