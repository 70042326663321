import React from 'react';
import ErrorContent from './ErrorContent';
import SuccessContent from './SuccessContent';

export const InfoContent = ({ data, error }) => {
  const hasErrors = error && Object.keys(error).length > 0;
  const urgencyLevel = data?.station.priority;
  const isUrgent = urgencyLevel === '1' || urgencyLevel === '2';

  const caseId = data?.caseDetails?.caseId;
  const stationData = data?.station;

  return hasErrors ? (
    <ErrorContent id={caseId} urgency={!isUrgent} station={stationData} />
  ) : (
    <SuccessContent id={caseId} urgency={!isUrgent} station={stationData} />
  );
};
