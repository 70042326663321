import { useCallback } from 'react';
import useRegSearchStore from '../../hooks/store/useRegSearchStore';
import { useAxiosInstance } from '../../api/axiosInstanceTwo';
import { XMLParser } from 'fast-xml-parser';

const useRegSearch = () => {
  const { setPcdData, setCaseData, setRsaData, setLoading, setError } = useRegSearchStore();
  const axiosInstance = useAxiosInstance();

  const pcdUrl = `${process.env.REACT_APP_PCD_API_BASE_URL}/pcd`;

  const fetchRegData = useCallback(
    async (registrationNumber, setFormik) => {
      setLoading(true);
      setError(null);

      let countryCode;

      try {
        countryCode = localStorage.getItem('regNumCountry');

        // Create the XML payload manually
        const xmlData = `<?xml version="1.0" encoding="UTF-8"?>
          <Vehicle_Search_Parameters>
            <Country_Code>${countryCode}</Country_Code>
            <Registration_Number>${registrationNumber.toUpperCase()}</Registration_Number>
          </Vehicle_Search_Parameters>`;

        const pcdResponse = await axiosInstance.post(pcdUrl, xmlData, {
          headers: { 'Content-Type': 'application/xml' },
        });

        if (pcdResponse.status === 200) {
          // Parse the XML response into JSON using fast-xml-parser
          const parser = new XMLParser();
          const jsonData = parser.parse(pcdResponse.data);

          // Check if data exists and then map to required fields
          if (jsonData && jsonData.Vehicle) {
            const data = jsonData.Vehicle;
            // console.log('PCD data received and parsed:', data);

            setPcdData(data);

            // Prepare new Formik values
            const newFormikValues = {
              address: data.Address || '',
              bodyType: data['Body_Type'] || '',
              city: data.City || '',
              colour: data.Colour || '',
              dateOfFirstRegistration: String(data['Date_of_First_Registration'] || ''),
              fuelType: data['Fuel_Type'] || '',
              insuranceCompany: data['Insurance_Company'] || '',
              lastName: data['Last_Name'] || '',
              length: String(data.Length || ''),
              make: data.Make || '',
              model: data.Model || '',
              numberOfAxlesInOperation: data['Number_of_Axles_in_Operation'] || '',
              organizationNumber: data['Organization_Number'] || '',
              registrationNumber: data['Registration_Number'] || '',
              totalNumberOfAxles: data['Total_Number_of_Axles'] || '',
              vin: data.VIN || '',
              weight: String(data.Weight || ''),
              width: String(data.Width || ''),
              year: String(data.Year || ''),
              zip: String(data.Zip || ''),
            };

            // Log the new values to the console
            // console.log('Setting Formik values:', newFormikValues);

            // Update Formik values
            setFormik.setFieldValue('pcdData', newFormikValues);
          }
        } else {
          setError({ status: pcdResponse.status, message: pcdResponse.statusText });
        }

        // Adding the RSA section back
        const rsaResponse = await axiosInstance.get(
          `${process.env.REACT_APP_RSA_API_BASE_URL}?registrationNumber=${registrationNumber}`
        );
        if (rsaResponse) {
          const rsaCaseData = rsaResponse.data;
          // console.log('RSA Response:', rsaCaseData);

          setRsaData(rsaCaseData.cases || rsaCaseData);
          setCaseData(rsaCaseData.cases || rsaCaseData);
        }
      } catch (error) {
        setError({ status: error.response?.status || 500, message: error.message });
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setError, axiosInstance, pcdUrl, setPcdData, setRsaData, setCaseData]
  );

  return { fetchRegData };
};

export default useRegSearch;
