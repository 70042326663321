import { create } from 'zustand';

const useRegSearchStore = create((set) => ({
  pcdData: null,
  caseData: null,
  rsaData: null,
  loading: false,
  error: null,
  hasSearched: false,

  setPcdData: (data) => set({ pcdData: data, hasSearched: true }),
  setCaseData: (data) => set({ caseData: data, hasSearched: true }),
  setRsaData: (data) => set({ rsaData: data, hasSearched: true }),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
  reset: () =>
    set({
      pcdData: null,
      caseData: null,
      rsaData: null,
      hasSearched: false,
      loading: false,
      error: null,
    }),
}));

export default useRegSearchStore;
