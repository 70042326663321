import React from 'react';
import { Box, Alert } from '@mui/material';
import CaseList from './CaseList';
import CarContainerCard from './CarContainerCard';
import NoCaseSuccess from '../CaseStatus/NoCaseSuccsess';
import LoadingCar from '../../../Loading/LoadingCar';
import useRegSearchStore from '../../../../hooks/store/useRegSearchStore';

const SearchResultsDisplay = () => {
  const { loading, pcdData, rsaData, caseData, hasSearched, error } = useRegSearchStore(
    (state) => ({
      loading: state.loading,
      pcdData: state.pcdData,
      rsaData: state.rsaData,
      caseData: state.caseData,
      error: state.error,
      hasSearched: state.hasSearched,
    })
  );

  console.log('Loading:', loading);
  console.log('PCD Data:', pcdData);
  console.log('RSA Data:', rsaData);
  console.log('Error:', error);

  if (!hasSearched && !loading) {
    return <></>;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {loading ? (
        <LoadingCar />
      ) : (
        <>
          {error && (
            <Alert severity="error" variant="filled">
              {error.message}
            </Alert>
          )}
          {pcdData && <CarContainerCard data={pcdData} />}
          {caseData.length > 0 ? <CaseList caseData={caseData} /> : <NoCaseSuccess />}
        </>
      )}
    </Box>
  );
};

export default SearchResultsDisplay;
