import { countryToCurrency } from '../utils/Mapping/countryToCurrency';
import { replaceEmptyStringsWithNull } from '../utils/replaceEmptyStringsWithNull';

export const buildRequestBody = (values) => {
  console.log('buildRequestBody', values);

  replaceEmptyStringsWithNull(values);

  const currentDateTime = new Date().toISOString();
  const isCurrentDateTime = currentDateTime.split('T')[0] === values?.dateTime?.split('T')[0];

  if (isCurrentDateTime) {
    values.dateTime = values.dateTime.split('T')[0];
  }

  if (values.location?.lat && values.location?.lon) {
    values.location.lat = values.location.lat.toString();
    values.location.lon = values.location.lon.toString();
  }

  const countryCode = values.location?.countryCodeISO;
  if (countryToCurrency[countryCode]) {
    values.CurrencyISOCode = countryToCurrency[countryCode];
  }

  const caseType = '012w00000006iZTAAY';

  const createCaseBody = {
    registrationNumber: values?.registrationNumber,
    countryCode: values?.location?.countryCode,
    caseType: caseType,
    currencyISOCode: values?.currencyISOCode,
    caseDescription: values?.caseDescription,
    incidentCause: values?.coverCause,
    incidentDescription: values?.caseDescription,
    priority: parseInt(values?.priority),
    incidentDate: values?.dateTime,
    desiredAssistanceFrom: values?.dateTime,
    accountId: values?.accountId || null,
    channel: values?.channel,
    coverCause: null,
    Vehicle: {
      vin: values?.pcdData?.vin,
      make: values?.pcdData?.make,
      model: values?.pcdData?.model,
      type: values?.pcdData?.bodyType,
      insuranceCompany: values?.pcdData?.insuranceCompany,
      carProducedYear: values?.pcdData?.year,
      zip: values?.pcdData?.zip || null,
      city: values?.pcdData?.city || null,
      address: values?.pcdData?.address || null,
      firstName: values?.pcdData?.firstName || null,
      lastName: values?.pcdData?.lastName || null,
    },
    onSiteContact: {
      name: values?.onSiteContact?.contactName,
      phoneNumber: values?.onSiteContact?.countryCode + values?.onSiteContact?.phone,
    },
    caseLocation: {
      address: values?.location?.address,
      zip: values?.location?.postalCode,
      city: values?.location?.city,
      country: values?.location?.country,
      latitude: values?.location?.lat,
      longitude: values?.location?.lon,
      description: values?.location?.locationMessage,
    },
  };

  return createCaseBody;
};
