import React, { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import LogoutButton from '../Button/LogoutButton';

const LogOutDialog = ({ handleClose }) => {
  const { instance } = useMsal();
  const [open, setOpen] = useState(false);

  const handleLogoutRedirect = () => {
    localStorage.clear();
    instance.logoutRedirect().catch((error) => console.log(error));
  };

  return (
    <>
      <LogoutButton handleClick={() => setOpen(true)} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Logging out for today?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are about to log out. Are you sure? Is everything saved
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="success" onClick={handleLogoutRedirect}>
            Log out
          </Button>
          <Button variant="outlined" color="error" onClick={() => setOpen(false)} autoFocus>
            Cancle
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LogOutDialog;
