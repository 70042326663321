import React from 'react';
import CustomErrorContainer from './CustomErrorContainer';
import UrgentCase from './UrgentCase';
import { Box, Typography } from '@mui/material';

const ErrorContent = ({ id, urgency, station }) => {
  const handleViewCase = () => {
    const url = process.env.REACT_APP_MISSING_DATA + `${id}/view`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const isUrgent = urgency === '1. IMMEDIATE PRIORITY' || urgency === '2. HIGH PRIORITY';

  return (
    <>
      <CustomErrorContainer
        severity="error"
        title="Somthing is missing"
        message="Looks like there is some missing data in the case. You need to move over to SalseForce to complete the case.  To complete the case, you need to add the missing data inside Salesforce"
        actions={[{ label: 'View Case in SalesForce', onClick: handleViewCase }]}
      >
        <Typography>
          {isUrgent ? (
            <Box
              sx={{
                boxShadow: 3,
              }}
            >
              <UrgentCase stationDetails={station} />
            </Box>
          ) : (
            <></>
          )}
        </Typography>
      </CustomErrorContainer>
    </>
  );
};

export default ErrorContent;
