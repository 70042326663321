import { useState } from 'react';
import { Box, Card, Typography } from '@mui/material';
import CaseStatusAlert from '../CaseStatus/CaseStatusAlert';
import ViewMore from '../../../Button/ViewMore';

const CaseList = ({ caseData }) => {
  const [displayedCases, setDisplayedCases] = useState(3);

  console.log('Case data', caseData)

  const handleLoadMore = () => {
    setDisplayedCases((prev) => prev + 3);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        boxShadow: 2,
        p: 1,
      }}
      component={Card}
    >
      <Box>
        <Typography variant="h6">Cases from last 7 days:</Typography>
      </Box>
      {caseData.map((caseItem) => (
        <CaseStatusAlert key={caseItem.caseId} data={caseItem} />
      ))}
      {displayedCases < caseData.length && <ViewMore handleOnClick={handleLoadMore} />}
    </Box>
  );
};

export default CaseList;
