import React from 'react';
import { useParams } from 'react-router-dom';
import { useRsaCaseData } from '../../hooks/query/useRsaCaseData';
import CarLoading from '../../components/Loading/CarLoading';
import PageLayout from './PageLayout';

const ReviewPage = () => {
  const { id } = useParams();
  const { data: caseData, isLoading, isError } = useRsaCaseData(id);

  console.log('ReviePage caseData, parernt', caseData);

  return (
    <>
      {isLoading && <CarLoading state="loading" />}
      {isError && <CarLoading state="error" />}
      {!isLoading && !isError && caseData && <PageLayout caseData={caseData} />}
      {/* {!isLoading && !isError && caseData && <ReviewPageAccordion caseData={caseData} />} */}
    </>
  );
};

export default ReviewPage;
