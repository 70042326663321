import React, { useContext } from 'react';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { ColorModeContext } from '../../Styles/Theme';
import { IconButton } from '@mui/material';

const ThemeSwitch = () => {
  const { toggleColorMode } = useContext(ColorModeContext);
  const themeMode = useContext(ColorModeContext).themeMode;

  return (
    <IconButton onClick={toggleColorMode} variant="contained">
      {themeMode === 'light' ? <DarkModeIcon /> : <LightModeIcon />}
    </IconButton>
  );
};

export default ThemeSwitch;
