export const formatCarData = (data) => {
    return {
      registrationNumber: data['Registration_Number'],
      organizationNumber: data['Organization_Number'],
      lastName: data['Last_Name'],
      address: data['Address'],
      zip: data['Zip'],
      city: data['City'],
      bodyType: data['Body_Type'],
      year: data['Year'],
      make: data['Make'],
      model: data['Model'],
      vin: data['VIN'],
      colour: data['Colour'],
      insuranceCompany: data['Insurance_Company'],
      weight: data['Weight'],
      length: data['Length'],
      width: data['Width'],
      dateOfFirstRegistration: data['Date_Of_First_Registration'],
      fuelType: data['Fuel_Type'],
      numberOfAxlesInOperation: data['Number_of_Axles_In_Operation'],
      totalNumberOfAxles: data['Total_Number_of_Axles'],
      automaticTransmission: data['Automatic_Transmission'],
    };
  };