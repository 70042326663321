import { Box } from '@mui/material';
import React from 'react';
import ExtraServicesSection from './ExstraServiceSection';
import { InfoContent } from '../content/InfoContent';

const InfoSection = ({ formValues, error }) => {

  console.log('InfoSection, errors: ', error)
  console.log('InfoSection, FormValues: ', formValues)

  return (
    <Box>
      <InfoContent data={formValues} error={error} />
      <ExtraServicesSection formValues={formValues.extraService} />
    </Box>
  );
};

export default InfoSection;
