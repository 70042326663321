import React from 'react';
import { Autocomplete, TextField, Tooltip } from '@mui/material';
import { channelOptions } from './options/channelOptions';

const ChannelSelect = ({ value, onChange, error }) => {
  return (
    <Tooltip title="Please select where the caller is calling from" placement="top">
      <Autocomplete
        id="channel-autocomplete"
        options={channelOptions}
        getOptionLabel={(option) => option.name || ''}
        value={channelOptions.find((option) => option.name === value) || null}
        onChange={(event, newValue) => {
          onChange({
            target: {
              name: 'channel',
              value: newValue ? newValue.name : '',
            },
          });
        }}
        renderInput={(params) => (
          <TextField {...params} label="Channel" variant="outlined" error={error} fullWidth />
        )}
      />
    </Tooltip>
  );
};

export default ChannelSelect;