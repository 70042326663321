import categorizeData from "../components/reviewPage/utils/categorizeData";

const defaultInitialValues = {
  registrationNumber: '',
  selectedCountry: 'NO',
  pcdData: {
    address: '',
    bodyType: '',
    city: '',
    colour: '',
    dateOfFirstRegistration: '',
    fuelType: '',
    insuranceCompany: '',
    lastName: '',
    length: '',
    make: '',
    model: '',
    numberOfAxlesInOperation: '',
    organizationNumber: '',
    registrationNumber: '',
    totalNumberOfAxles: '',
    vin: '',
    weight: '',
    width: '',
    year: '',
    zip: '',
  },
  onSiteContact: {
    contactName: '',
    countryCode: '+47',
    phone: '',
  },
  channel: '',
  searchTerm: '',
  location: {
    locationMessage: '',
    address: '',
    postalCode: '',
    city: '',
    country: '',
    countryCodeISO: '',
    lon: '',
    lat: '',
  },
  coverageDetails: {
    coverageInfo: '',
    coverageId: '',
  },
  coverCause: '',
  caseComment: '',
  priority: '3',
  stationMessage: '',
  dateTime: null,
};



export const useInitialValues = (formType, mergedCaseData) => {
  const getInitialValues = () => {
    switch (formType) {
      case 'defaultForm':
        return defaultInitialValues;
      case 'mergedCaseForm':
        return categorizeData(mergedCaseData);
      default:
        return {};
    }
  };

  return { initialValues: getInitialValues() };
};
