import { AuthenticatedTemplate } from '@azure/msal-react';
import { Box, Card } from '@mui/material';
import { Outlet } from 'react-router-dom';
import PageHeader from '../components/typography/PageHeader';
import { useSidebar } from '../Context/SidebarContext';
import SidebarDrawer from '../components/drawer/SidebarDrawer';
import RightsidebarContent from '../components/sidebar/RightsidebarContent';
import LeftSidebarContent from '../components/sidebar/LeftSidebarContent';

const Authenticated = () => {
  const { isSidebarOpen, closeSidebar } = useSidebar();

  const flex = {
    display: 'flex',
    flex: '0 0 1',
  };

  return (
    <AuthenticatedTemplate>
      <Box sx={flex}>
        <Box>
          <SidebarDrawer state={true} position="left">
            <LeftSidebarContent />
          </SidebarDrawer>
        </Box>
        <Box
          component={Card}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            margin: 3,
            padding: 2,
          }}
        >
          <PageHeader />
          <Outlet />
        </Box>
        {isSidebarOpen && (
          <SidebarDrawer state={isSidebarOpen} position="right" onClose={closeSidebar} width={300}>
            <RightsidebarContent />
          </SidebarDrawer>
        )}
      </Box>
    </AuthenticatedTemplate>
  );
};

export default Authenticated;
