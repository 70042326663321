import React, { useEffect, useRef, useState } from 'react';
import atlas, { HtmlMarker } from 'azure-maps-control';
import 'azure-maps-control/dist/atlas.min.css';
import '../../Styles/AzureMaps.css';
import SearchResultCard from './Props/SearchResultCard';
import { searchCoordinates } from '../../api/azureMapsService';
import useSearchStore from '../../hooks/store/useSearchStore';

const NewMapComponent = ({ formikValues, setFieldValue }) => {
  const mapRef = useRef(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [cardData, setCardData] = useState(null);
  const [marker, setMarker] = useState(null);
  const subscriptionKey = process.env.REACT_APP_AZURE_MAPS_SUBSCRIPTION_KEY;

  const { searchResult, setSearchResult } = useSearchStore((state) => ({
    searchResult: state.searchResult,
    setSearchResult: state.setSearchResult,
  }));

  useEffect(() => {
    const defaultPosition = [10.75, 59.91];
    const defaultZoom = 15;

    const position =
      formikValues.location.lat && formikValues.location.lon
        ? [formikValues.location.lon, formikValues.location.lat]
        : defaultPosition;

    if (mapRef.current && !mapInstance) {
      const map = new atlas.Map(mapRef.current, {
        center: position,
        zoom: defaultZoom,
        authOptions: {
          authType: atlas.AuthenticationType.subscriptionKey,
          subscriptionKey: subscriptionKey,
        },
      });

      map.events.add('ready', () => {
        setMapInstance(map);
        map.events.add('contextmenu', handleMapRightClick); // eslint-disable-line
      });
    }
  }, [subscriptionKey, mapInstance, formikValues.location.lat, formikValues.location.lon]); // eslint-disable-line

  useEffect(() => {
    if (mapInstance && searchResult.lat && searchResult.lon) {
      const position = [searchResult.lon, searchResult.lat];

      if (!marker) {
        const newMarker = createMarker(mapInstance, position);
        mapInstance.markers.add(newMarker);
        setMarker(newMarker);
        mapInstance.setCamera({ center: position, zoom: 14 });
      } else {
        marker.setOptions({ position });
        mapInstance.setCamera({ center: position, zoom: 14 });
      }

      setCardData({
        position,
        address: searchResult.address || 'No Address Found',
        title: searchResult.title || 'Location',
      });

      updateFormikWithSearchResult(searchResult);
    }
  }, [searchResult, mapInstance, marker, setFieldValue]); // eslint-disable-line

  const handleMapRightClick = async (e) => {
    const position = e.position ? [e.position[0], e.position[1]] : null;
    if (position) {
      if (marker) {
        marker.setOptions({ position });
        mapInstance.setCamera({ center: position, zoom: 14 });
      } else {
        const newMarker = createMarker(mapInstance, position);
        mapInstance.markers.add(newMarker);
        setMarker(newMarker);
        mapInstance.setCamera({ center: position, zoom: 14 });
      }

      const addressDetails = await searchCoordinates(position[1], position[0]);
      if (addressDetails) {
        updateFormikAndStore(position, addressDetails);
      }
    }
  };

  const createMarker = (map, position) => {
    const marker = new HtmlMarker({
      position: position,
      htmlContent: '<div><div class="pin bounce"></div><div class="pulse"></div></div>',
      draggable: true,
    });

    map.events.add('dragend', marker, async (e) => {
      const newPosition = e.target.getOptions().position;
      const lon = newPosition[0];
      const lat = newPosition[1];

      const addressDetails = await searchCoordinates(lat, lon);
      if (addressDetails) {
        updateFormikAndStore(newPosition, addressDetails);
      }
    });

    return marker;
  };

  const updateFormikAndStore = (position, addressDetails) => {
    setFieldValue('location.address', addressDetails.address || '');
    setFieldValue('location.postalCode', addressDetails.postalCode || '');
    setFieldValue('location.city', addressDetails.city || '');
    setFieldValue('location.country', addressDetails.country || '');
    setFieldValue('location.countryCodeISO', addressDetails.countryCodeISO || '');
    setFieldValue('location.lon', position[0] || '');
    setFieldValue('location.lat', position[1] || '');

    setSearchResult({
      address: addressDetails.address,
      postalCode: addressDetails.postalCode,
      city: addressDetails.city,
      country: addressDetails.country,
      countryCodeISO: addressDetails.countryCodeISO,
      lat: position[1],
      lon: position[0],
      title: addressDetails.title,
    });

    setCardData({
      position,
      address: addressDetails.address || 'No Address Found',
      title: addressDetails.title || 'Location Found',
    });
  };

  const updateFormikWithSearchResult = (searchResult) => {
    setFieldValue('location.address', searchResult.address || '');
    setFieldValue('location.postalCode', searchResult.postalCode || '');
    setFieldValue('location.city', searchResult.city || '');
    setFieldValue('location.country', searchResult.country || '');
    setFieldValue('location.countryCodeISO', searchResult.countryCodeISO || '');
    setFieldValue('location.lon', searchResult.lon || '');
    setFieldValue('location.lat', searchResult.lat || '');
  };

  const closeCard = () => setCardData(null);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <div ref={mapRef} style={{ width: '100%', height: '100%' }} />
      {cardData && (
        <SearchResultCard
          address={cardData.address || 'No Address Available'}
          position={cardData.position || [0, 0]}
          companyName={cardData.title ? cardData.title : 'Location Found'}
          onClose={closeCard}
        />
      )}
    </div>
  );
};

export default NewMapComponent;
