import React from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import OpenSideBar from '../Button/OpenSideBar';

const PageHeader = () => {
  const location = useLocation();

  const getHeading = (pathname) => {
    switch (pathname) {
      case '/':
        return 'Logged in user';
      case '/case':
        return 'Create Case';
      case '/review':
        return 'Review Cases';
      default:
        return 'Page Title';
    }
  };

  return (
    <Box
      sx={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center', p: 2 }}
    >
      <Typography variant="h1">{getHeading(location.pathname)}</Typography>
      <OpenSideBar />
    </Box>
  );
};

export default PageHeader;
