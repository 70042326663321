import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ErrorOutline, Warning, Info, CheckCircle } from '@mui/icons-material';

const severityIcons = {
  error: <ErrorOutline />,
  warning: <Warning />,
  info: <Info />,
  success: <CheckCircle />,
};

const severityColors = {
  error: {
    background: '#f8d7da',
    border: '#f5c6cb',
    text: '#721c24',
  },
  warning: {
    background: '#fff3cd',
    border: '#ffeeba',
    text: '#856404',
  },
  info: {
    background: '#d1ecf1',
    border: '#bee5eb',
    text: '#0c5460',
  },
  success: {
    background: '#d4edda',
    border: '#c3e6cb',
    text: '#155724',
  },
};

const CustomErrorContainer = ({
  severity = 'error',
  title = 'Error',
  message = 'Something went wrong. Please try again.',
  actions = [],
  sx = {},
  children,
}) => {
  const { background, border, text } = severityColors[severity];

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        padding: 2,
        backgroundColor: background,
        border: `1px solid ${border}`,
        borderRadius: 1,
        color: text,
        ...sx,
      }}
    >
      <Box sx={{ marginRight: 2, alignSelf: 'flex-start' }}>{severityIcons[severity]}</Box>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h6" sx={{ marginBottom: 0 }}>
          {title}
        </Typography>
        <Box marginRight={3}>
          <Typography variant="body2" sx={{ marginBottom: 1 }}>
            {message}
          </Typography>
        </Box>
        <Box sx={{ marginTop: 1 }}>
          {actions.map((action, index) => (
            <Button
              key={index}
              variant="contained"
              color={severity === 'error' ? 'error' : 'primary'}
              onClick={action.onClick}
              sx={{ marginLeft: 1 }}
            >
              {action.label}
            </Button>
          ))}
        </Box>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};

export default CustomErrorContainer;
