import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, CircularProgress, Box, Button } from '@mui/material';
import { searchAddress } from '../../../api/azureMapsService';
import useSearchStore from '../../../hooks/store/useSearchStore';
import handleSelect from './handleSelect';
import MapIcon from '@mui/icons-material/Map';
import CancelIcon from '@mui/icons-material/Cancel';

const MapSearchAutocomplete = ({ isMapOpen, onMapToggle, setFieldValue }) => {
  const [inputValue, setInputValue] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const setSearchResult = useSearchStore((state) => state.setSearchResult);
  const clearSearchResult = useSearchStore((state) => state.clearSearchResult);

  useEffect(() => {
    clearSearchResult();

    const fetchResults = async () => {
      if (inputValue.length > 2) {
        setLoading(true);
        const addressResults = await searchAddress(inputValue);
        setResults(addressResults);

        console.log('Address Resutls:',addressResults)

        setLoading(false);
      } else {
        setResults([]);
      }
    };

    fetchResults();
  }, [inputValue]); // eslint-disable-line

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const selectedOption = inputValue;
      handleSelect(event, selectedOption, results, inputValue, setSearchResult);
      setInputValue('');
    }
  };

  const handleOptionSelect = (event, selectedOption) => {
    if (selectedOption) {
      const selectedResult = results.find(result => result.label === selectedOption);

      console.log('selectedResult', selectedResult)
      if (selectedResult) {
        setFieldValue('location.address', selectedResult.address || '');
        setFieldValue('location.postalCode', selectedResult.postalCode || '');
        setFieldValue('location.city', selectedResult.city || '');
        setFieldValue('location.country', selectedResult.country || '');
        setFieldValue('location.countryCodeISO', selectedResult.countryCodeISO || '');
        setFieldValue('location.lon', selectedResult.position.lon || '');
        setFieldValue('location.lat', selectedResult.position.lat || '');
      }
      handleSelect(event, selectedOption, results, inputValue, setSearchResult);
      setInputValue('');
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Autocomplete
          freeSolo
          options={results.map((result) => result.label)}
          inputValue={inputValue}
          onInputChange={(_event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={handleOptionSelect}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search"
              variant="outlined"
              fullWidth
              onKeyDown={handleKeyPress}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(props, option) => {
            const result = results.find((result) => result.label === option);
            return (
              <li {...props} key={result.key}>
                {result.label}
              </li>
            );
          }}
        />
      </Box>
      <Box sx={{ marginLeft: '8px', flexShrink: 0 }}>
        <Button onClick={onMapToggle} variant="contained">
          {isMapOpen ? <CancelIcon /> : <MapIcon />}
        </Button>
      </Box>
    </Box>
  );
};

export default MapSearchAutocomplete;
