import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'; // Import an error icon

const ValidatedTextField = ({ name, label, value, error, helperText, caseId, ...rest }) => (
  <TextField
    variant="standard"
    disabled
    name={name}
    label={label}
    value={value || ''}
    error={error}
    helperText={error ? helperText : ''}
    fullWidth
    InputLabelProps={{ shrink: true }}
    InputProps={{
      endAdornment: error ? (
        <InputAdornment position="end">
          <ErrorOutlineIcon color="error" />
        </InputAdornment>
      ) : null,
    }}
    {...rest}
  />
);

export default ValidatedTextField;
