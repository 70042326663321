import React, { useState, useEffect } from 'react';
import { Box, Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SectionHeading from './SectionHeading';

const Section = ({ heading, children, hasError, isComplete }) => {
  // Determine the initial state based on error or completeness
  const [isOpen, setIsOpen] = useState(!isComplete || hasError); 

  // Effect to open/close the section when props change
  useEffect(() => {
    setIsOpen(!isComplete || hasError);
  }, [hasError, isComplete]);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Box sx={{ marginBottom: 3, marginLeft: 2, marginRight: 2 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ cursor: 'pointer' }}
        onClick={handleToggle}
      >
        <SectionHeading title={heading} />
        <Box display="flex" alignItems="center" gap={1}>
          {!isComplete  ? (
            <ErrorIcon sx={{ color: 'red' }} />
          ) : isComplete ? (
            <CheckCircleIcon sx={{ color: 'green' }} />
          ) : null}
          <IconButton size="small">
            <ExpandMoreIcon sx={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }} />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ borderBottom: '1px solid gray', marginY: 1 }} />

      <Collapse in={isOpen}>
        <Box sx={{ 
          display: 'flex',
          gap: 2,
          padding: 2
        }}>
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};

export default Section;
