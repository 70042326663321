import { useState } from 'react';
import { UnauthenticatedTemplate } from '@azure/msal-react';
import { Box, Button } from '@mui/material';
import BgImg from '../Assets/truck.jpg';

export const Unauthenticated = ({ onClick }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
  };

  return (
    <UnauthenticatedTemplate>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          backgroundImage: `url(${BgImg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Button variant="contained" color="primary" onClick={handleClick} disabled={isLoading}>
          {isLoading ? 'Signing in...' : 'Sign in'}
        </Button>
      </Box>
    </UnauthenticatedTemplate>
  );
};
