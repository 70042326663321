import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import useRegSearch from '../../hooks/Search/useRegSearch';
import BaseLayout from './CaseLayout/BaseLayout';
import { getErrorMessage } from '../../utils/getErrorMessage';
import { useInitialValues } from '../../hooks/useInitialValues';
import { useValidationSchema } from '../../hooks/useValidationSchema';
import useSearchStore from '../../hooks/store/useSearchStore';

const CasePageForm = ({ submitForm }) => {
  if (typeof submitForm !== 'function') {
    console.error('submitForm is not a function');
  }

  const { searchResult, setSearchResult } = useSearchStore(); // eslint-disable-line
  const [openCase, setOpenCase] = useState(false); // eslint-disable-line
  const [caseData, setCaseData] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);

  const { validationSchema } = useValidationSchema('otherForm');
  const { initialValues } = useInitialValues('defaultForm');

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (!values.dateTime) {
        values.dateTime = new Date().toISOString();
      }

      if (Object.keys(formik.errors).length > 0) {
        console.log('Validation errors before submit:', formik.errors);
        return;
      }

      submitForm(values);
      console.log('Form Submitted!', values);
    },
    validateOnChange: true,
    validateOnBlur: true,
  });

  const { loading, pcdError, rsaError, fetchRegData } = useRegSearch();

  // Memoize input handlers
  const handleInputChange = useCallback(
    (e) => {
      formik.handleChange(e);
    },
    [formik]
  );

  const handleBlur = useCallback(
    (e) => {
      formik.handleBlur(e);
    },
    [formik]
  );

  const handleChannelChange = (e) => {
    formik.setFieldValue('channel', e.target.value);
  };

  const handlePriorityChange = (e) => {
    formik.setFieldValue('priority', e.target.value);
  };

  // Handle search results directly here
  const handleSearch = useCallback(() => {
    if (formik.values.registrationNumber) {
      fetchRegData(formik.values.registrationNumber, (result) => {
        console.log('Search result:', result);

        // Update formik values directly
        const fieldsToUpdate = {
          'location.city': result.city || '',
          'location.postalCode': result.postalCode || '',
          'location.countryCode': result.countryCode || '',
          'location.country': result.country || '',
          'location.address': result.address || '',
          'location.lon': result.position?.lon || '',
          'location.lat': result.position?.lat || '',
        };

        Object.entries(fieldsToUpdate).forEach(([key, value]) => {
          formik.setFieldValue(key, value);
        });

        console.log('Formik values after update:', formik.values);
      });
      setHasSearched(true);
    }
  }, [fetchRegData, formik]);

  // Fetch data from localStorage once on mount
  useEffect(() => {
    const storedData = localStorage.getItem('RSA_CAR_DETAILS');
    if (storedData) {
      setCaseData(JSON.parse(storedData));
    }
  }, []);

  const pcdErrorMessage = getErrorMessage(pcdError);
  const rsaErrorMessage = getErrorMessage(rsaError);

  useEffect(() => {
    if (pcdErrorMessage) {
      console.error('PCD Error:', pcdErrorMessage);
    }
    if (rsaErrorMessage) {
      console.error('RSA Error:', rsaErrorMessage);
    }
  }, [pcdErrorMessage, rsaErrorMessage]);

  useEffect(() => {
    if (formik.errors) {
      console.error('Formik validation errors:', formik.errors);
    }
  }, [formik.values, formik.errors]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <BaseLayout
        formik={formik}
        searchResult={searchResult}
        caseData={caseData}
        openCase={openCase}
        hasSearched={hasSearched}
        loading={loading}
        pcdErrorMessage={pcdErrorMessage}
        rsaErrorMessage={rsaErrorMessage}
        handleInputChange={handleInputChange}
        handleBlur={handleBlur}
        handleChannelChange={handleChannelChange}
        handlePriorityChange={handlePriorityChange}
        handleSearch={handleSearch}
      />
    </form>
  );
};

export default CasePageForm;
