import React, { useState } from 'react';
import { Snackbar, Alert } from '@mui/material';
import CasePageForm from './CasePageForm';
import { useNavigate } from 'react-router';
import { buildRequestBody } from '../../api/requestbody';
import { usePostRsaCase } from '../../hooks/query/usePostRsaCase';

const CasePage = () => {
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();

  // Use the custom mutation hook
  const { mutateAsync: postRsaCase, isLoading, error } = usePostRsaCase();

  const handleSubmit = async (values) => {
    console.log('handleSubmit called with values:', values); // Log the values received

    const body = buildRequestBody(values);

    try {
      // Send data to the server using the mutation function
      const response = await postRsaCase(body);

      const caseId = response.id;

      if (caseId) {
        navigate(`/review/${caseId}`);
      }
    } catch (error) {
      console.error('Submission error:', error);
      setSnackbarMessage('Error submitting form');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason !== 'clickaway') setOpenSnackbar(false);
  };

  return (
    <>
      <CasePageForm submitForm={handleSubmit} />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" variant="filled">
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {isLoading && <div>Loading...</div>} {/* Optional loading indicator */}
      {error && <div>Error: {error.message}</div>} {/* Optional error message display */}
    </>
  );
};

export default CasePage;
