import React, { useState } from 'react';
import { Box, Tooltip, TextField, FormControl, Button } from '@mui/material';
import { flexBoxDefault, flexBoxRow } from '../../../Styles/defaultStyle';

import RegistrationSearch from '../../../components/FormFields/RegistrationNumber/RegistrationSearch';
import CoverCauseSelector from '../../../components/FormFields/SelectOptions/CoverCauseSelector';
import ChannelSelect from '../../../components/FormFields/SelectOptions/ChannelSelect';
import PrioritySelect from '../../../components/FormFields/SelectOptions/PrioritySelect';
import PhoneNumber from '../../../components/FormFields/PhoneNumber/PhoneNumber';
import MapComponent from '../../../components/Map/MapComponent';
import MapSearchAutocomplete from '../../../components/Map/Search/MapSearchAutocomplete';
import SearchResultsDisplay from '../../../components/UI/UserFeedback/SearchResults/SearchResultsDisplay';

const BaseLayout = ({
  formik,
  loading,
  pcdErrorMessage,
  rsaErrorMessage,
  handleInputChange,
  handleBlur,
  handleChannelChange,
  handlePriorityChange,
  handleSearch,
}) => {
  const [isMapOpen, setIsMapOpen] = useState();

  // Handler to toggle map visibility
  const handleMapToggle = () => {
    setIsMapOpen((prev) => !prev);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <RegistrationSearch
        registrationNumber={formik.values.registrationNumber}
        setFormik={formik}
        onChange={handleInputChange}
        onSearch={handleSearch}
        loading={loading}
        error={formik.touched.registrationNumber && formik.errors.registrationNumber}
      />
      {formik.values.pcdData && Object.keys(formik.values.pcdData).length > 0 && (
        <SearchResultsDisplay
          hasSearched={true}
          loading={loading}
          pcdErrorMessage={pcdErrorMessage}
          rsaErrorMessage={rsaErrorMessage}
        />
      )}
      <ChannelSelect
        value={formik.values.channel}
        onChange={handleChannelChange}
        error={formik.touched.channel && Boolean(formik.errors.channel)}
      />
      <Box sx={flexBoxRow}>
        <PhoneNumber
          value={formik.values.onSiteContact.phone}
          onChange={(e) => formik.setFieldValue('onSiteContact.phone', e.target.value)}
          onBlur={formik.handleBlur}
          error={formik.errors.onSiteContact?.phone}
          touched={formik.touched.onSiteContact?.phone}
          countryCode={formik.values.onSiteContact.countryCode}
          onCountryCodeChange={(value) => formik.setFieldValue('onSiteContact.countryCode', value)}
          fullWidth
          sx={{ flex: 1 }}
        />
        <Tooltip title="Contact name for the person on site" placement="top">
          <TextField
            id="contactName"
            name="onSiteContact.contactName"
            label="Contact at Location"
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.onSiteContact?.contactName || ''}
            error={
              formik.touched.onSiteContact?.contactName &&
              Boolean(formik.errors.onSiteContact?.contactName)
            }
            helperText={
              formik.touched.onSiteContact?.contactName && formik.errors.onSiteContact?.contactName
            }
            sx={{ flex: 1 }}
          />
        </Tooltip>
      </Box>
      <CoverCauseSelector
        value={formik.values.coverCause || ''}
        onChange={(value) => formik.setFieldValue('coverCause', value)}
        error={formik.touched.coverCause && Boolean(formik.errors.coverCause)}
        helperText={formik.touched.coverCause && formik.errors.coverCause}
      />
      <Box sx={flexBoxDefault}>
        <MapSearchAutocomplete isMapOpen={isMapOpen} onMapToggle={handleMapToggle} setFieldValue={formik.setFieldValue} />
        {isMapOpen && (
          <Box sx={{ height: '400px' }}>
            <MapComponent formikValues={formik.values} setFieldValue={formik.setFieldValue} />
          </Box>
        )}
      </Box>
      <Box sx={flexBoxDefault}>
        <Tooltip title="Add message for location. Ex: inside a garage" placement="top">
          <TextField
            variant="outlined"
            name="locationMessage"
            placeholder="Location description"
            value={formik.values.location?.locationMessage || ''}
            onChange={(e) => formik.setFieldValue('location.locationMessage', e.target.value)}
            onBlur={handleBlur}
            error={Boolean(
              formik.touched.location?.locationMessage && formik.errors.location?.locationMessage
            )}
            helperText={
              formik.touched.location?.locationMessage && formik.errors.location?.locationMessage
            }
          />
        </Tooltip>
      </Box>
      <Box sx={flexBoxRow}>
        <PrioritySelect
          value={formik.values.priority}
          onChange={handlePriorityChange}
          onBlur={handleBlur}
          error={formik.errors.priority}
          touched={formik.touched.priority}
          formikProps={formik}
        />
        <Tooltip title="Add message for station" placement="top">
          <FormControl variant="filled" fullWidth>
            <TextField
              variant="outlined"
              name="stationMessage"
              placeholder="Message to station"
              value={formik.values.stationMessage}
              onChange={handleInputChange}
              onBlur={handleBlur}
            />
          </FormControl>
        </Tooltip>
      </Box>
      <Button type="submit" variant="contained" color="success">
        Create Case
      </Button>
    </Box>
  );
};

export default BaseLayout;
